import React from 'react';
import ContactForm from './ContactForm';

function Contact() {
  return (
    <section className='row contact'>
      <div className='col col-left'></div>
      <div className='col col-right'>
        <h2>Contact Us</h2>
        <ContactForm />
      </div>
    </section>
  );
}

export default Contact;

import React from "react"
import Layout from '../components/layout'
import {Container, Header, Grid} from 'semantic-ui-react';
import Contact from '../components/contact-form/Contact'

const ContactPage = () =>{
    return(
        <Layout>
            <Container text>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                        <Header as='h1'>Contact Us</Header>
                            <Contact />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>   
            
             </Container>

        </Layout>
    ) 
}

export default ContactPage